<template>
  <div class="shortLinkManager" v-loading.fullscreen.lock="fullscreenLoading">
    <div class="flex">
      <div class="search">
        <div>页面名称:</div>
        <el-input v-model.trim="name" style="width: 200px; margin: 0 10px" clearable></el-input>
        <el-button type="primary" @click="handleSearch">查询</el-button>
        <el-button @click="handleReset">重置</el-button>
      </div>
      <el-button @click="handleEdit(0)">新增</el-button>
    </div>
    <div style="height: 30px"></div>
    <Table
      :tableData="tableData"
      :tableHead="tableHead"
      :slotKeys="['activityStatusDesc', 'button', 'claimedNumber', 'backgroundImageUrl']"
      :isExpand="false"
      :isPagination="true"
      :showIndex="false"
    >
      <template #activityStatusDesc="{ data }">
        <span :style="{ color: colorEnmu[data.row.activityStatusDesc] }">{{ data.row.activityStatusDesc }}</span>
      </template>
      <template #claimedNumber="{ data }">
        <span>{{ data.row.claimedNumber }}</span>
      </template>
      <template #backgroundImageUrl="{ data }">
        <div v-if="data.row.backgroundImageUrl" style="color: blue;" @click="handleView(data.row)">查看</div>
      </template>
      <!-- 0 待发布 1已发布 2已结束 -->
      <template #button="{ data }">
        <el-button @click="handleEdit(1, data.row)" type="primary" size="mini">编辑</el-button>
        <el-button
          v-if="[1].includes(data.row.activityStatus)"
          @click="handleEdit(3, data.row)"
          type="primary"
          size="mini"
          >结束</el-button
        >
        <el-button
          v-if="[0, 2].includes(data.row.activityStatus)"
          @click="handleEdit(4, data.row)"
          type="primary"
          size="mini"
          >发布</el-button
        >
      </template>
    </Table>
    <Pagination :pagination="pagination" @handleChange="handleChangePage"></Pagination>
    <el-dialog v-model="dialogVisible" :title="title" width="70%" :destroy-on-close="true" :before-close="handleClose">
      <el-form
        ref="ruleFormRef"
        :model="formData"
        :rules="rules"
        label-position="right"
        class="demo-ruleForm"
        :size="formSize"
        status-icon
      >
        <el-form-item label="页面名称" prop="name">
          <el-input
            style="width: 460px"
            v-model.trim="formData.name"
            placeholder="该名称仅在星后台显示，非页面抬头名称，1~20个字符"
          />
        </el-form-item>
        <el-form-item label="活动类型" prop="activityType">
          <el-radio-group v-model="formData.activityType">
            <el-radio
              v-for="(item, index) in radioList"
              :key="index"
              :label="item.value"
              :disabled="isDisabled"
              @input="handleActivityType(item.value)"
              >{{ item.label }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item label="背景图" prop="backgroundImageUrl" v-if="isCoupon || isPublicze">
          <div style="display: flex; flex-direction: column">
            <el-upload
              class="avatar-uploader"
              action="#"
              :show-file-list="false"
              :http-request="handleUpload"
              :accept="uploadRule.extensions"
              v-loading="uploadLoading"
            >
              <img v-if="formData.backgroundImageUrl" :src="formData.backgroundImageUrl" style="width: 160px" />
              <el-button style="width: 160px" type="primary" plain v-else>上传</el-button>
            </el-upload>
            <div>图片大小：375 x 667</div>
          </div>
        </el-form-item>
        <el-form-item label="优惠券" prop="couponCode" v-if="isCoupon">
          <el-button
            v-if="hasCoupon"
            style="width: 160px"
            type="primary"
            plain
            @click="openProductDialog('birthdayCoupons')"
            >选择优惠券</el-button
          >
          <div v-else style="position: relative; width: 420px; height: 110px">
            <div style="width: 420px; height: 110px; display: flex">
              <div class="left">
                <div style="line-height: 1.5">
                  {{ formData.activityCouponInfo.scheme }}{{ unitsObj[formData.activityCouponInfo.type] }}
                </div>
                <div style="line-height: 1.5">无门槛</div>
              </div>
              <div class="right">
                <div style="line-height: 1.5">{{ formData.activityCouponInfo.name }}</div>
                <div style="line-height: 1.5">有效期：{{ formData.activityCouponInfo.validityTimeStr }}</div>
                <div style="line-height: 1.5">
                  仅{{ formData.activityCouponInfo.usedRange === 1 ? '房费' : '商品' }}可用
                </div>
                <div style="line-height: 1.5">
                  发行数量：{{
                    formData.activityCouponInfo.issueNum === -1 ? '不限制' : formData.activityCouponInfo.realIssueNum
                  }}
                </div>
              </div>
            </div>
            <div style="position: absolute; right: -18px; top: -8px" v-if="!isDisabled" @click="handleDelete">X</div>
          </div>
        </el-form-item>
        <el-form-item label="领券按钮样式" prop="buttonType" v-if="isCoupon">
          <el-radio-group v-model="formData.buttonType" style="display: flex">
            <div
              style="position: relative; margin-right: 50px; color: #fff"
              v-for="(item, index) in btnList"
              :key="index"
            >
              <el-button
                :style="[
                  {
                    width: '160px',
                    height: '33px',
                    'background-color': item.bgcColor,
                    border: index == 2 ? '1px solid #000' : '',
                  },
                ]"
                round
                @click="handleBtnStyle(index)"
              >
                <div :style="[{ color: item.textColor }]">立即领取</div>
              </el-button>
              <el-radio style="position: absolute; top: -15px" :label="index" size="large">{{}}</el-radio>
            </div>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="个人可领数" prop="personalNumber" v-if="isCoupon">
          <el-input
            v-model.trim="formData.personalNumber"
            style="max-width: 380px"
            placeholder="1~99"
            class="input-with-select"
            :clearable="false"
          >
            <template #prepend>
              <el-select v-model="formData.personalType" :disabled="isDisabled" style="width: 115px">
                <el-option v-for="(item, index) in timePicker" :key="index" :label="item.label" :value="item.value" />
              </el-select>
            </template>
            <template #append> 张 </template>
          </el-input>
        </el-form-item>
        <el-form-item label="可领总数" prop="recoverableTotalNumber" v-if="isCoupon">
          <el-input
            v-model.trim="formData.recoverableTotalNumber"
            style="max-width: 380px"
            placeholder="1~99999"
            class="input-with-select"
          >
            <template #prepend>
              <el-select v-model="formData.recoverableTotalType" :disabled="isDisabled" style="width: 115px">
                <el-option v-for="(item, index) in timePicker" :key="index" :label="item.label" :value="item.value" />
              </el-select>
            </template>
            <template #append> 张 </template>
          </el-input>
        </el-form-item>
        <el-form-item label="页面地址" prop="path" v-if="isDefine">
          <el-input style="width: 460px" v-model.trim="formData.path" />
        </el-form-item>
        <el-form-item label="页面参数" prop="pageParameter" v-if="isDefine">
          <el-input style="width: 460px" v-model.trim="formData.pageParameter" />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="handleClose">取消</el-button>
          <el-button type="primary" @click="handleSubmit"> 保存 </el-button>
        </div>
      </template>
      <SelectCoupou
        :couponByUserAuth="true"
        :isAll="true"
        :onlySelectOne="true"
        :isFixedStore="0"
        :needFilterStore="true"
        :storeCodeList="stores"
        v-if="coupouDialogVisible"
        :dialogVisible="dialogVisible"
        @update-dialogVisible="rectiveProducts"
        :hasPro="[]"
      ></SelectCoupou>
    </el-dialog>
    <div class="imageView">
      <el-dialog v-model="isShowImage" width="50%" :before-close="handleCloseImage">
        <el-image :src="imageUrl" mode="cover"></el-image>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { ElMessage, ElMessageBox } from 'element-plus'
import API from '@/plugin/cms-manager/service/api.js'
import API1 from '../service/api.js'
import Pagination from '@/component/base/Pagination1/index'
import { uploadFileMixin, uploadfileRuleMixin } from '@/mixins/uploadFile.js'
import { Table } from '@/component/base'
import SelectCoupou from '../component/select-coupou.vue'
const colorEnmu = {
  已发布: 'green',
  已结束: 'red',
  待发布: 'orange',
}
function validatePersonalNumber(rule, value, callback) {
  const num = parseInt(value)
  if (isNaN(num) || num < 1 || num > 99) {
    callback(new Error('请输入 1 - 99 之间的数字'))
  } else {
    callback()
  }
}
function validateTotalNumber(rule, value, callback) {
  const num = parseInt(value)
  if (isNaN(num) || num < 1 || num > 99999) {
    callback(new Error('请输入 1 - 99999 之间的数字'))
  } else {
    callback()
  }
}
export default {
  components: { Pagination, Table, SelectCoupou },
  data() {
    return {
      isShowImage: false,
      uploadLoading: false,
      fullscreenLoading: false,
      imageUrl: '',
      name: '',
      title: '新增活动',
      dialogVisible: false,
      formData: {
        activityCouponInfo: {},
      },
      tableData: [],
      tableHead: [
        {
          prop: 'name',
          label: '页面名称',
          align: 'center',
          width: '160',
        },
        {
          prop: 'shortLink',
          label: '短链接',
          align: 'center',
          width: '300',
        },
        {
          prop: 'activityTypeDesc',
          label: '活动类型',
          align: 'center',
          width: '120',
        },
        {
          prop: 'activityStatusDesc',
          label: '状态',
          align: 'center',
          width: '120',
        },
        {
          prop: 'backgroundImageUrl',
          label: '背景图',
          align: 'center',
          width: '120',
        },
        {
          prop: 'couponName',
          label: '关联优惠券',
          align: 'center',
          width: '160',
        },
        {
          prop: 'personalNumberDesc',
          label: '个人可领数量',
          align: 'center',
          width: '120',
        },
        {
          prop: 'recoverableTotalNumberDesc',
          label: '可领总数',
          align: 'center',
          width: '120',
        },
        {
          prop: 'claimedNumber',
          label: '已领数量',
          align: 'center',
          width: '120',
          formatter: row => {
            return row.claimedNumber || 0
          },
        },
        {
          prop: 'path',
          label: '页面地址',
          align: 'center',
          width: '280',
        },
        {
          prop: 'pageParameter',
          label: '页面参数',
          align: 'center',
          width: '300',
        },
        {
          prop: 'createUser',
          label: '创建人',
          align: 'center',
          width: '160',
        },
        {
          prop: 'telephone',
          label: '手机号',
          align: 'center',
          width: '160',
        },
        {
          prop: 'createTime',
          label: '创建时间',
          align: 'center',
          width: '160',
        },
        {
          prop: 'button',
          label: '操作',
          fixed: 'right',
          align: 'center',
          width: '200',
        },
      ],
      rules: {
        name: [
          { required: true, message: '请输入页面名称', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' },
        ],
        backgroundImageUrl: [{ required: true, message: '请选择背景图', trigger: 'blur' }],
        path: [{ required: true, message: '请输入页面地址', trigger: 'blur' }],
        buttonType: [{ required: true, message: '请选择领券按钮样式', trigger: 'blur' }],
        personalNumber: [
          { required: true, message: '请输入个人可领数', trigger: 'blur' },
          { validator: validatePersonalNumber, trigger: 'blur' },
        ],
        recoverableTotalNumber: [
          { required: true, message: '请输入可领总数', trigger: 'blur' },
          { validator: validateTotalNumber, trigger: 'blur' },
        ],
        couponCode: [{ required: true, message: '请选择优惠券', trigger: 'change' }],
      },
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        pageCount: 5,
        pageSizes: [10, 20, 30, 40, 50],
      },
      radioList: [
        {
          label: '领券活动',
          value: 0,
        },
        {
          label: '宣传页',
          value: 1,
        },
        {
          label: '自定义活动',
          value: 2,
        },
      ],
      btnList: [
        { bgcColor: '#3b180f', textColor: '#ffffff' },
        { bgcColor: '#e69e3a', textColor: '#ffffff' },
        { bgcColor: 'transparent', textColor: '#000000' },
      ],
      timePicker: [
        {
          label: '总计',
          value: 3,
        },
        {
          label: '每天',
          value: 0,
        },
        {
          label: '每周',
          value: 1,
        },
        {
          label: '每月',
          value: 2,
        },
      ],
      colorEnmu,
      coupouDialogVisible: false,
      unitsObj: {
        1: '折',
        2: '元',
        3: '分钟',
        4: '元',
      },
      uploadRule: {
        businessType: 'ACTIVITY_BACKDROP',
      },
      activityPath: {
        0: 'pagesActivity/orderCoupon',
        1: 'pagesActivity/advertisingPage',
      },
      stores: [],
    }
  },
  mounted() {
    this.queryList()
  },
  methods: {
    getShops() {
      const params = {
        list: [],
      }
      API1.getStoreList(params).then(res => {
        if (res.code === '200') {
          this.stores = res.data.map(item => item.code)
        }
      })
    },
    handleCloseImage() {
      this.isShowImage = false
    },
    handleView(row) {
      this.isShowImage = row.backgroundImageUrl ? true : false
      this.imageUrl = row.backgroundImageUrl
    },
    handleActivityType(idx) {
      console.log(idx, '=====idx')
    },
    handleBtnStyle(idx) {
      this.formData.buttonType = idx
      console.log(this.btnList[idx], '当前选中的值')
    },
    handleDelete() {
      this.formData.activityCouponInfo = null
      this.formData.couponCode = ''
    },
    rectiveProducts({ selectSkus, update }) {
      this.isShowCoupouDialog(false)
      this.formData.activityCouponInfo = selectSkus[0]
      this.formData.couponCode = selectSkus[0].code
      this.$refs.ruleFormRef.clearValidate('couponCode')
    },
    openProductDialog(type) {
      this.isShowCoupouDialog(true)
    },
    isShowCoupouDialog(val) {
      this.coupouDialogVisible = val
    },
    async handleUpload(file) {
      file.fileName = file.file.name
      const res = await uploadFileMixin(
        file,
        this.uploadRule.businessType,
        () => {
          this.uploadLoading = true
        },
        fileUrl => {
          this.uploadLoading = false
          this.formData.backgroundImageUrl = fileUrl
          this.$refs.ruleFormRef.clearValidate('backgroundImageUrl')
        },
      )
    },
    // 查询
    handleSearch: _.throttle(function() {
      this.pagination.pageNum = 1
      this.queryList()
    }, 1000),
    // 重置
    handleReset: _.throttle(function() {
      this.name = ''
      this.pagination.pageNum = 1
      this.queryList()
    }, 1000),
    queryList() {
      this.fullscreenLoading = true
      const { pageNum, pageSize } = this.pagination
      let params = { pageNum, pageSize, name: this.name }
      API.queryLinkNew(params).then(res => {
        if (res.code == '200') {
          this.fullscreenLoading = false
          this.tableData = res?.data?.records || []
          this.pagination.total = res?.data?.totalRecords || 0
        } else {
          this.fullscreenLoading = false
          ElMessage.error(res.msg)
        }
      })
    },
    handleChangePage(pages) {
      Object.assign(this.pagination, pages)
      this.queryList()
    },
    handleClose() {
      this.dialogVisible = false
      if (this.title == '新增活动') {
        this.$refs.ruleFormRef.resetFields()
        this.formData = {}
      }
    },
    // 保存
    async handleSubmit() {
      ElMessageBox.confirm(`确认保存？`, '操作确认', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          await this.$refs.ruleFormRef.validate(async (valid, fields) => {
            if (valid) {
              const user = JSON.parse(localStorage.getItem('vuex'))
              let params = {
                name: this.formData.name, // 页面名称
                activityType: this.formData.activityType, //活动类型
                backgroundImageUrl: this.isCoupon || this.isPublicze ? this.formData.backgroundImageUrl : undefined, //背景图
                buttonType: this.isCoupon ? this.formData.buttonType : undefined, //按钮样式
                backgroundColor: this.isCoupon ? this.btnList[this.formData.buttonType].bgcColor : undefined, //按钮背景色
                fontColor: this.isCoupon ? this.btnList[this.formData.buttonType].textColor : undefined, //按钮字体颜色
                path: this.formData.path ? this.formData.path : this.activityPath[this.formData.activityType], // 页面地址
                pageParameter: this.formData.pageParameter, // 页面参数
                pageParameter: this.isDefine ? this.formData.pageParameter : undefined, // 页面参数
                telephone: user?.user?.userInfo?.userSaasUserVO?.cellPhone, // 联系电话
                createUser: user?.user?.username, // 创建人
                code: this.formData.code ? this.formData.code : undefined, // 编辑的标识
                couponCode: this.isCoupon ? this.formData.activityCouponInfo.code : undefined, // 优惠券ID
                couponName: this.isCoupon ? this.formData.activityCouponInfo.name : undefined, // 优惠券名称
                recoverableTotalType: this.isCoupon ? this.formData.recoverableTotalType : undefined,
                recoverableTotalNumber: this.isCoupon ? Number(this.formData.recoverableTotalNumber) : undefined,
                personalType: this.isCoupon ? this.formData.personalType : undefined,
                personalNumber: this.isCoupon ? Number(this.formData.personalNumber) : undefined,
              }
              // 编辑和新增
              const res = await API.addLinkNew(params)
              if (res.code == '200') {
                this.dialogVisible = false
                this.pagination.pageNum = 1
                ElMessage.success('保存成功')
                this.queryList()
              } else {
                ElMessage.error(res.msg)
              }
            } else {
              console.log('请完善信息填写', fields)
            }
          })
        })
        .catch(() => {
          ElMessage.info('取消保存')
        })
    },
    async handleEdit(val, row) {
      const user = JSON.parse(localStorage.getItem('vuex'))
      if (val == 3 || val == 4) {
        ElMessageBox.confirm(`确认${val == 3 ? '结束' : '发布'}？`, '操作确认', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(async () => {
            const status = {
              0: 1, // 待发布-》发布
              1: 2, // 已发布 -》 结束
              2: 1, // 已结束 -》发布
            }
            const parmas = {
              code: row.code,
              activityStatus: status[row.activityStatus],
              updateUser: user?.user?.username,
            }
            const res = await API.updateStatusNew(parmas)
            if (res.code == '200') {
              this.queryList()
              ElMessage.success('更改成功')
            } else {
              ElMessage.error(res.msg)
            }
          })
          .catch(() => {
            ElMessage.info('取消成功')
          })
      } else {
        this.getShops()
        const enmu = {
          0: '新增活动',
          1: '编辑活动',
        }
        this.title = enmu[val]
        this.dialogVisible = true
        // 获取图片的约束信息
        const res = await uploadfileRuleMixin(this.uploadRule)
        console.log(res, 'res=====')
        this.uploadRule = {
          ...res,
          businessType: res.businessType,
        }
        // 新增默认值
        if (val == 0) {
          this.formData = {
            activityType: 0,
            buttonType: 0,
            personalType: 0,
            recoverableTotalType: 1,
          }
          return
        } else {
          // 编辑
          this.formData = row
        }
      }
    },
  },
  computed: {
    isDisabled() {
      return [1, 2].includes(this.formData.activityStatus)
    },
    hasCoupon() {
      return this.formData.activityCouponInfo == null ? true : false
    },
    // 活动
    isCoupon() {
      return this.formData.activityType == 0 ? true : false
    },
    // 宣传页
    isPublicze() {
      return this.formData.activityType == 1 ? true : false
    },
    // 自定义
    isDefine() {
      return this.formData.activityType == 2 ? true : false
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item {
  .el-form-item__label {
    width: 120px !important;
  }
}
.shortLinkManager {
  margin: 20px;
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .el-button {
      width: 80px;
    }
  }
  .search {
    display: flex;
    align-items: center;
    .el-button {
      width: 80px;
    }
  }
  .dialog-footer {
    display: flex;
    justify-content: center;
    .el-button {
      width: 200px;
    }
  }
  .setting {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #02a7f0;
  }
  .setting :nth-child(n + 1) {
    margin-right: 12px;
  }
  .left {
    width: 30%;
    height: 110px;
    border: 1px solid #ccc;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-right: none;
  }
  .right {
    width: 70%;
    height: 110px;
    border: 1px solid #ccc;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 12px;
  }
  .imageView {
    ::v-deep .el-dialog__body {
      text-align: center !important;
    }
  }
}
</style>
